@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Blinker:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
html,
body {
  height: 100vh;
}
#root {
  height: 100%;
}
a {
  text-decoration: none;
}
a :visited {
  color: black;
}
.contact-link:visited {
  color: #fff;
}
button:active {
  color: #aaaaaa;
}

#score-number {
  font-family: "Blinker", sans-serif;
}
.blinker-font {
  font-family: "Blinker", sans-serif;
}

#spinner {
  height: 1.5rem;
  width: 1.5rem;
  border: 0.25rem solid black;
  border-top: 0.25rem solid #ffffff00 !important;
  border-radius: 50%;
  animation: spin infinite 1.3s linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
